import React, { Component } from 'react';
import {
    TopBar,
    TopHeader,
    HeaderModern,
    Slider,
    Footer,
    Loader,
    CarouselClient,
    HeaderTransparent, Header
} from '../layouts/general';
import { Featured } from '../layouts/general/featured';
import { Services } from '../layouts/general/services';
import { Project } from '../layouts/general/projects';
import { Callback } from '../layouts/general/callback';
import { Blog } from '../layouts/general/blog';
import {Counter, ProgessAccordion} from "../layouts/home05";
import {Benefit, Testimonials} from "../layouts/home06";

class Home02 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Home '
                }
            ],
        }
    }
    
    render() {
        return (
            <div className="bg-body">
                <div className="boxed">
                    {/*<Loader />*/}
                    <TopBar />
                    <TopHeader />
                    {
                        this.state.headers.map(data => (
                            <HeaderModern data={data} key={data.id}/>
                        ))
                    }

                    <Slider />
                    
                    <Featured />

                    <Services />

                    <Project />

                    {/*<Callback />*/}

                    {/*<Blog />*/}
                    <CarouselClient />

                    <Footer />
                </div>
            </div>
        );
    }
}

export default Home02;