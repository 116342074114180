import React, { Component } from 'react';
import { Link } from "react-router-dom";
class BoxPost extends Component {
	constructor(props) {
        super(props);
        this.state = {
            infobox: [
                {
                    id: '1',
                    srcimg: 'images/about/01.jpg',
                    subtitle:'Founder & CEO',
                    title: 'CIDY GAMA',
                    description: 'Mr Cidy Gama has a reputable experience in managing and leading high performance ventures.',
                    classdivider: 'dividers dividers-about-post'
                },
                {
                    id: '2',
                    srcimg: 'images/about/02.jpg',
                    subtitle:'Founder & COO',
                    title: 'ADAM MHANDO',
                    description: 'With his years of experience in operations and management, Eng. Adam is on a mission of making your dream a reality.',
                    classdivider: 'dividers dividers-about-post'
                },
            ]
            
        }
    }
    render() {
        return (
            <div>
                {
                    this.state.infobox.map(data =>(
                        <div key={data.id} >
                        <article className="entry">
                            <div className="feature-post">                                    
                                <Link to="#">
                                    <img src={data.srcimg} alt="img" />
                                </Link>                                    
                            </div>
                            <div className="content-post">
                                <div className="position">{data.subtitle}</div>	
                                <h3 className="title-post"><Link to="#">{data.title}</Link></h3>
                                <div className="entry-post">
                                    <p>{data.description}</p>
                                </div>
                                <div className="wrap-button s2">
                                    <Link className="flat-button" to="#">View Profile</Link>
                                </div>                                  
                            </div>
                        </article>
                         <div className={data.classdivider}></div>
                        </div>
                    ))
                }
                
            </div>
        )
                                
    }
}

export default BoxPost;