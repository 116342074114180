import React, { Component } from 'react';
import { Link } from "react-router-dom";
import TabPortfolio from './TabPortfolio';

class MainPortfolio extends Component {
	constructor(props) {
        super(props);
        this.state = {
			itemcontent: [
				{
					id: '1',
					srcimg: 'images/projects/project1.png',
					classitem: 'item marine',
					title: 'Marine Solutions',
					category: 'Marine Construction',
					subcategory: '',
				},
				{
					id: '2',
					srcimg: 'images/projects/project2.png',
					classitem: 'item marine',
					title: 'Haul Construction',
					category: 'Marine Construction',
					subcategory: '',
				},
				{
					id: '3',
					srcimg: 'images/projects/project3.png',
					classitem: 'item infrastructure',
					title: 'Road Construction',
					category: 'Infrastructure Construction',
					subcategory: '',
				},
				{
					id: '4',
					srcimg: 'images/projects/project4.png',
					classitem: 'item infrastructure',
					title: 'Road Construction',
					category: 'Infrastructure Construction',
					subcategory: '',
				},
				{
					id: '5',
					srcimg: 'images/projects/project5.png',
					classitem: 'item marine',
					title: 'Haul Construction',
					category: 'Marine Construction',
					subcategory: '',
				},
			]
        }
    }
    render() {
        return (
				/* <!-- Portfolio --> */
				<section className="flat-row pd-portfolio-s3" id="work">
					<TabPortfolio />
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className="dividers portfolio"></div>
								<div className="flat-portfolio">             
									<div className="portfolio-wrap grid one-three clearfix">
										{
											this.state.itemcontent.map(data =>(
												<div key={data.id} className={data.classitem}>
													<div className="wrap-iconbox">
														<div className="featured-post">
															<img src={data.srcimg} alt="img" />
														</div>
														<div className="title-post">
															<Link to="#">{data.title}</Link>
														</div>
														<div className="category-post">
															<Link to="#" title="">{data.category} </Link>
															<Link to="#" title=""> {data.subcategory}</Link>
													</div>
												</div> 
											</div>
										/* <!-- portfolio-item --> */
											))
										}
									</div>
									{/* <!-- /.portfolio-wrap --> */}
								</div>
								{/* <!-- /.flat-portfolio --> */}
							</div>

							<div className="col-md-12">
								<div className="wrap-button s1">
									{/*<Link className="flat-button" to="">LOAD MORE</Link>*/}
								</div>
							</div>
						</div>
					</div>
				</section> 

				 
        );
    }
}

export default MainPortfolio;