// import Home01 from './Home01'
import Home02 from './Home02'
import Home03 from './Home03'
import Home04 from './Home04'
import Home05 from './Home05'
import Home06 from './Home06'
import TeamGrid from './TeamGrid'
import OverView from './OverView'
import AboutUs from './AboutUs'
import Services from './Services'
import Blog from './Blog'
import BlogGrid from './BlogGrid'
import RiskManagement from './RiskManagement'
import Portfolio from './Portfolio'
import Portfolio2 from './Portfolio2'
import Portfolio3 from './Portfolio3'
import Contact01 from './Contact01'
import Contact02 from './Contact02'
import BlogSingle from './BlogSingle'
import Infrastructure from "./Infrastructure";
import Marine from "./Marine";
import Transportation from "./Transportation";
import Construction from "./Construction";
import StoneQuarry from "./StoneQuarry";
import GeneralTrading from "./GeneralTrading";
import VisionMission from "../layouts/aboutus/VisionMission";
import Vision from "./Vision";

const routes = [
    { path: '/', component: Home02},
    { path: '/about-v1', component: AboutUs},
    { path: '/services-v1', component: Services},
    { path: '/portfolio-v3', component: Portfolio},
    { path: '/blog', component: Blog},
    { path: '/blog-grid', component: BlogGrid},
    { path: '/contact-v1', component: Contact01},
    { path: '/contact-v2', component: Contact02},
    { path: '/blog-single', component: BlogSingle},
    { path: '/Infrastructure', component: Infrastructure},
    { path: '/Marine', component: Marine},
    { path: '/Transportation', component: Transportation},
    { path: '/Construction', component: Construction},
    { path: '/StoneQuarry', component: StoneQuarry},
    { path: '/GeneralTrading', component: GeneralTrading},
    {path: '/Overview', component: OverView},
    {path: '/about-vision', component: VisionMission},


]

export default routes;