import React, {Component} from 'react';

class OverView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgslide: [
                {
                    id: '1',
                    srcimg: 'images/about/a.png'
                },
                {
                    id: '2',
                    srcimg: 'images/about/b.png'
                }
            ],
            content: [
                {
                    id: '1',
                    title: 'About Us',
                    description:
                        <>
                            <p>Sumry's Enterprises Ltd, is a Class-1 construction firm with its head offices based at Sumbawanga, Rukwa region and a branch office at Dar es Salaam .</p>
                            <p> The company was incorporated on 5th September 2009 by Mr. Hamoud Sumry (MD) under the company laws of United Republic of Tanzania. </p>
                            <p>The core activities of our company are infrastructure construction (Roads & Bridges) and Building Construction, Transportation and General Trading.
                                We also deal with supply of all types of furniture, electronics, hardware and building materials.</p>
                            <p>Our client base range from the Government up to Non-Governmental Institutions including Public and Private Sectors.</p>
                        </>
                },
                {
                    id: '2',
                    title: 'Our Prominent Clients',
                    description:
                        <>
                            <li>TANROADS</li>
                            <li>TARURA</li>
                            <li>TANZANIA PORTS AUTHORITY (TPA)</li>
                            <li>MUNICIPAL COUNCILS</li>
                        </>

                }
            ],
            blogtimeline: [
                // {
                //     id: '1',
                //     year: '2011',
                //     title: 'Started Gama Incorporation Ltd',
                //     description: 'Gama Incorporation Ltd deals with solving technological problems. We are a team of professionals with a passion for excellence and a commitment to deliver quality services to our clients.',
                // },
                // {
                //     id: '2',
                //     year: '2011',
                //     title: 'Started Gama Incorporation Ltd',
                //     description: 'Gama Incorporation Ltd deals with solving technological problems. We are a team of professionals with a passion for excellence and a commitment to deliver quality services to our clients.',
                // },
            ]

        }
    }

    render() {
        return (
            <div>
                <div className="flexslider s2">
                    <div className="flat-slides">
                        <ul className="slides">
                            {
                                this.state.imgslide.map(data => (
                                    <li key={data.id}>
                                        <img src={data.srcimg} alt="img"/>
                                    </li>
                                ))
                            }

                        </ul>
                    </div>
                </div>
                {
                    this.state.content.map(data => (
                        <div className="box-content" key={data.id}>
                            <div className="title">{data.title}</div>
                            <p>{data.description}</p>
                            <div className="dividers dividers-bc-v4"></div>
                        </div>
                    ))
                }

                {
                    this.state.blogtimeline.map(data => (
                        <div className="flat-text-block-timeline" key={data.id}>
                            <div className="year">{data.year}</div>
                            <div className="flat-timeline-content">
                                <div className="box-content">
                                    <div className="title">{data.title}</div>
                                    <p>{data.description}</p>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>

        )

    }
}

export default OverView;