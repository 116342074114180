import React, { Component } from 'react';
import { Link } from "react-router-dom";
class MainServices extends Component {
	constructor(props) {
        super(props);
        this.state = {
            titleServices: [
                {
                    id: 1,
					title: 'What We Do',
					description: 'Our company offers high standard services in the following areas.',
                }
			],
			imagebox: [
				{
					id: '1',
					imgsrc: 'images/services/infastructure_construction.png',
					title: 'Infrastructure Construction',
					description: "We provide reputable high quality service in Construction and Infrastructure Development.",
					url: '/Infrastructure'
				},
				{
					id: '2',
					imgsrc: 'images/services/marine_construction.png',
					title: 'Marine Construction',
					description: 'We provide high quality standard marine construction services that meet our clients needs .',
					url: '/Marine'
				},
				{
					id: '3',
					imgsrc: 'images/services/stone_quarry.png',
					title: 'Stone Quarry and Crusher and Other Plants',
					description: 'With our high grade plants we produce all types of materials required in the construction industry .',
					url: '/StoneQuarry'
				},
				{
					id: '4',
					imgsrc: 'images/services/construction.png',
					title: 'Construction Machinery and  Equipments',
					description: 'We own and maintain all types of construction machinery and equipments. They include excavators, back hoe, tippers and many more .',
					url: '/Construction'
				},
				{
					id: '5',
					imgsrc: 'images/services/transportation.png',
					title: 'Transportation',
					description: 'With our fleet of trucks and trailers we provide transportation services to our clients  across the country.',
					url: '/Transportation'
				},
				{
					id: '9',
					imgsrc: 'images/services/general_trading.png',
					title: 'General  Trading',
					description: 'We deal with general trading and merchandise through our outlets in Sumbawanga and Dar es Salaam .',
					url: '/GeneralTrading'
				}
			]
        }
    }
    render() {
        return (
			<section className="flat-row pd-services-post">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							{
								this.state.titleServices.map(data =>(
									<div className="title-section center s1" key={data.id} >
										<h2>{data.title} </h2>
										<p className="sub-title-section">{data.description} </p>
									</div>
								))
							}
							<div className="dividers dividers-imagebox"></div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="wrap-imagebox-grid">
									{
										this.state.imagebox.map(data =>(
											<div className="flat-imagebox services-grid item" key={data.id} >
												<div className="flat-imagebox-inner"  >
													<div className="flat-imagebox-image">
														<img src={data.imgsrc} alt="img" />
													</div>
													<div className="flat-imagebox-header">
														<h3 className="flat-imagebox-title">
															{/*<Link to={data.url}>{data.title}</Link>*/}
															<Link to="#">{data.title}</Link>
														</h3>
													</div>
													<div className="flat-imagebox-content">
														<div className="flat-imagebox-desc">{data.description}</div>
														{/*<div className="flat-imagebox-button">*/}
														{/*	<Link to={data.url}>Read More</Link>*/}
														{/*</div>*/}
													</div>
												</div>
											</div> 
								
										))
									} 
							</div>
						</div>
					</div>
				</div>
			</section>

				



        );
    }
}

export default MainServices;