const menus = [
    {
        id: 1,
        name: 'Home',
        linkmenu: '/',
    },
    {
        id: 2,
        name: 'About',
        linkmenu: '/about-v1',
    },
    {
        id: 3,
        name: 'Services',
        linkmenu: '/services-v1',
    },
    {
        id: 4,
        name: 'Projects',
        linkmenu: '/portfolio-v3',
    },
    {
        id: 5,
        name: 'Contact',
        linkmenu: '/contact-v1',
        sub: 'Get In Touch',
        links: '/contact-v1',
    }
]

export default menus;