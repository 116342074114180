import React, { Component } from 'react';
import SlideBar  from "./SlideBar";
import MainPost  from "./MainPost";
import ConstructionPost from "./ConstructionPost";
class ConstructionDetails extends Component {
	constructor(props) {
        super(props);
        this.state = {
            itembox: [
                {
                    id: 1,
					title: 'Infrastructure Construction',
					classicon: 'icon-benefit',
					boxcontent: 'We provide reputable high quality service in Construction and Infrastructure Development.'
				},
            ]
        }
    }
    render() {
        return (
			
			<section className="flat-row pd-services-widget">
                        <div className="container">
                            <div className="row flat-tabs" data-effect ="fadeIn">
								<SlideBar />
                                <ConstructionPost />
                            </div>				
                        </div>
			        </section>
                    // flat-row-iconbox
        );
    }
}

export default ConstructionDetails;