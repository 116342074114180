import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class UsefulLink extends Component {
    render() {
        return (
            <div className="widget widget-services">
                <ul className="one-half first">
                    <li><Link to="/about-v1" onClick={() => {window.location.href="/about-v1"}} title="">About Us</Link></li>
                    <li><Link to="/services-v1" onClick={() => {window.location.href="/services-v1"}} title="">Services</Link></li>
                    <li><Link to="/portfolio-v3" onClick={() => {window.location.href="/portfolio-v3"}} title="">Portfolio</Link></li>
                </ul>

                <ul className="one-half">
                    <li><Link to="/" onClick={() => {window.location.href="/"}} title="">FAQ's</Link></li>
                    <li><Link to="/" onClick={() => {window.location.href="/"}} title="">Resources</Link></li>
                    <li><Link to="/contact-v1" onClick={() => {window.location.href="/contact-v1"}} title="">Contact Us</Link></li>
                </ul>

            </div>
        /* <!-- /.widget-services --> */
        );
    }
}

export default UsefulLink;