import React, { Component } from 'react';

class TextMainFooter extends Component {
    render() {
        return (
            <div className="widget widget-text">
                <p>
                    SUMRY's Enterprises  is a leading Tanzania based company in road and civil engineering contracting and provision of Construction materials,
                    Mining Services, Office Furniture, Home Appliances, and Electronics meeting and satisfying the demands and expectations of our stakeholders.
                </p>

            </div>
            /* <!-- /.widget-text --> */
        );
    }
}

export default TextMainFooter;