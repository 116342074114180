import React, { Component } from 'react';
class BoxView  extends Component {
	constructor(props) {
        super(props);
        this.state = {
            imgslide: [
                {
                    id: '1',
                    srcimg: 'images/about/s01.jpg'
                },
                {
                    id: '2',
                    srcimg: 'images/about/s01.jpg'
                }
            ],
            content: [
                {
                    id: '1',
                    title: 'Get to know us',
                    description: 'Sumry Enterprises Limited is a Tanzanian company that provides a wide range of services in the fields of ICT and Engineering. We are a team of professionals with a passion for excellence and a commitment to deliver quality services to our clients.',
                }
            ],
            blogtimeline: [
                {
                    id: '1',
                    year: '2011',
                    title: 'Started Gama Incorporation Ltd',
                    description: 'Gama Incorporation Ltd deals with solving technological problems. We are a team of professionals with a passion for excellence and a commitment to deliver quality services to our clients.',
                },
                {
                    id: '2',
                    year: '2011',
                    title: 'Started Gama Incorporation Ltd',
                    description: 'Gama Incorporation Ltd deals with solving technological problems. We are a team of professionals with a passion for excellence and a commitment to deliver quality services to our clients.',
                },
            ]
            
        }
    }
    render() {
        return (
            <div>
                <div className="flexslider s2">
                    <div className="flat-slides">                            
                        <ul className="slides">
                            {
                                this.state.imgslide.map(data =>(
                                    <li key={data.id}>   
                                        <img src={data.srcimg} alt="img" />
                                    </li>
                                ))
                            }
                            
                        </ul> 
                    </div>
                </div> 
                    {
                        this.state.content.map(data =>(
                            <div className="box-content" key={data.id} >
                                <div className="title">{data.title}</div>
                                <p>{data.description}</p>
                                <div className="dividers dividers-bc-v4"></div>
                            </div>
                            ))
                    }
                
                    {
                        this.state.blogtimeline.map(data =>(
                            <div className="flat-text-block-timeline" key={data.id} >
                                <div className="year">{data.year}</div>
                                <div className="flat-timeline-content">
                                <div className="box-content">
                                    <div className="title">{data.title}</div>
                                    <p>{data.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
								
        )
                                
    }
}

export default BoxView;