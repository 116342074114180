import React, { Component } from 'react'
import {Link} from 'react-router-dom'
class ServicesBoxs1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataEvents: [
                {
                    id: 1,
                    title: 'Infrastructure Construction',
                    description: 'With our talented team of engineers and architects, we provide a wide range of infrastructure construction services',
                    url: '/Infrastructure'
                },
                {
                    id: 2,
                    title: 'Marine Construction',
                    description: 'Our experience in marine construction is second to none. We have a wide range of marine construction equipment and machinery',
                    url: '/Marine'
                },
                {
                    id: 3,
                    title: 'Stone Quarry, Crusher Plants and other Plants',
                    description: 'Our stone quarry, crusher plants and other plants guarantee you with the best construction materials needed for your projects',
                    url: '/StoneQuarry'
                },
            ]
        }
    }
    
    render() {
        return (
            <div className="row">
                {
                    this.state.dataEvents.map(data => (
                        <div className="col-md-4" key={data.id}>
                            <div className="iconbox-item">
                                <div className="iconbox style1">
                                    <div className="box-header">
                                        <div className={data.classicon}>
                                            
                                        </div>
                                        {/* <!-- /.icon-rounded --> */}
                                        <div className="box-title">
                                            <Link to={data.url} title="">{data.title}</Link>
                                        </div>
                                        {/* <!-- /.box-title --> */}
                                        </div>
                                        {/* <!-- /.box-header --> */}
                                    <div className="box-content">{data.description}</div>
                                    {/* <!-- /.box-content --> */}
                                </div>
                                 {/* <!-- /.iconbox --> */}
						    </div>
                            {/* <!-- /.iconbox-item --> */}
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default ServicesBoxs1;