import React, {Component} from 'react';

class ConstructionPost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabpost: [
                {
                    id: '1',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '2',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '3',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '4',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '5',
                    classcategory: 'wrap-one-half services',
                },
                {
                    id: '6',
                    classcategory: 'wrap-one-half services',
                },
            ],
            boxcontent: [
                {
                    id: '1',
                    title: 'Construction Machinery',
                    description:
                        <>
                            <p>
                                We own, operate and maintain a wide range of construction machinery such as excavators, back hoes, tippers, motor graders, road rollers, pavers, concrete mixers, concrete pumps, cranes, etc.
                            </p>
                            <p>
                                Our large fleet of construction machinery enables us to undertake projects of any size and complexity.
                            </p>
                        </>
                },
                {
                    id: '2',
                    title: 'Equipment Hire',
                    description:
                        <>
                            <p>
                                We hire out our construction machinery to our esteemed clients who require it for their projects.
                            </p>

                        </>
                },
                {
                    id: '3',
                    title: 'Equipment Service and Training',
                    description:
                        <>
                            <p>
                                Our dedicated team of highly qualified engineers and technicians are able to undertake all types of repairs and maintenance of construction machinery. We also provide training on the operation of construction machinery.
                            </p>
                        </>
                },
                {
                    id: '4',
                    title: 'We Serve our Clients with',
                    description:''
                },
            ],
            flatlist: [
                {
                    id: '1',
                    text: 'Quality construction equipment'
                },
                {
                    id: '2',
                    text: 'Professional equipment service and training'
                },
                {
                    id: '3',
                    text: 'Cost effective repair and maintenance services'
                },
            ],
            flattoggle: [
                {
                    id: '1',
                    title: 'Project Management',
                    content: 'We take full control of your construction project from ideation to completion. Our dedicated team of highly qualified engineers will ensure that your project is brought to completion in a timely manner .',
                    classtoggle: 'toggle-title active'
                },
                {
                    id: '2',
                    title: 'Safety at Work',
                    content: 'Safety is always a top priority on site. We adhere to OSHA regulations when undertaking projects.',
                    classtoggle: 'toggle-title'
                },
                {
                    id: '3',
                    title: 'Machinery Equipment',
                    content: 'We are equipped with a wide range of construction machinery such as excavators, back hoes, tippers, motor graders, road rollers, paver, bull dozer , and many more thus we guarantee your project completion.',
                    classtoggle: 'toggle-title'
                },
            ],
            titleplan: [
                {
                    id: '1',
                    title: 'Highly Reputable Services',
                }
            ],
            textplan: [
                {
                    id: '1',
                    text: 'Our successful projects are a testament to our commitment to quality and timely delivery of projects.'
                },
                {
                    id: '2',
                    text: 'We have a team of highly skilled and experienced engineers and technicians who control and deliver practical solutions, provide technical expertise and quality workmanship efficiency in every project.'
                },
            ]

        }
    }

    render() {
        return (
            <div className="col-md-9 content-tab">
                {
                    this.state.tabpost.map(data => (
                        <div className="content-inner" key={data.id}>
                            <div className={data.classcategory}>
                                <div className="one-half v1">
                                    <div className="box-content">
                                        {
                                            this.state.boxcontent.map(data => (
                                                <div key={data.id}>
                                                    <div className="title">{data.title}</div>
                                                    <p>{data.description}</p>
                                                    <div className="dividers dividers-bc-v1"></div>
                                                </div>
                                            ))
                                        }
                                        <ul className="flat-list">
                                            {
                                                this.state.flatlist.map(data => (
                                                    <li key={data.id}>{data.text} </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="one-half v2">
                                    <div className="flexslider s1">
                                        <div className="flat-slides">
                                            <ul className="slides">
                                                <li>
                                                    <img src="images/services/infastructure_construction.png"
                                                         alt="infastructure_construction"/>
                                                </li>

                                                <li>
                                                    <img src="images/services/infastructure_construction1.png"
                                                         alt="infastructure_construction"/>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="flat-accordion style">
                                        {
                                            this.state.flattoggle.map(data => (
                                                <div className="flat-toggle" key={data.id}>
                                                    <h6 className={data.classtoggle}>{data.title}</h6>
                                                    <div className="toggle-content">
                                                        <p>{data.content}</p>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="dividers dividers-bc-v2"></div>
                                <div className="box-content">
                                    {
                                        this.state.titleplan.map(data => (
                                            <div key={data.id} className="title">{data.title}</div>
                                        ))
                                    }
                                    {
                                        this.state.textplan.map(data => (
                                            <div key={data.id}>
                                                <p>{data.text}</p>
                                                <div className="dividers dividers-bc-v3"></div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>

                    ))
                }

            </div>
        )

    }
}

export default ConstructionPost;