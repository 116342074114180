import React, { Component } from 'react';
import { Link } from "react-router-dom";
import TabPortfolio from './TabPortfolio';

class Layout extends Component {
	constructor(props) {
        super(props);
        this.state = {
			itemcontent: [
				{
					id: '1',
					srcimg: 'images/projects/project1.png',
					classitem: 'item business savings trading',
					title: 'Business Solutions',
					category: 'Services /',
					subcategory: 'Trading',
				},
				{
					id: '2',
					srcimg: 'images/projects/project2.png',
					classitem: 'item management finance',
					title: 'Money Market',
					category: 'Services /',
					subcategory: 'Trading',
				},
				{
					id: '3',
					srcimg: 'images/projects/project3.png',
					classitem: 'item savings services',
					title: 'MO Insurance',
					category: 'Services /',
					subcategory: 'Trading',
				},
				{
					id: '4',
					srcimg: 'images/projects/project4.png',
					classitem: 'item finance trading',
					title: 'Financial Report',
					category: 'Management /',
					subcategory: 'Trading',
				},
				{
					id: '5',
					srcimg: 'images/projects/project5.png',
					classitem: 'item invoicing finance',
					title: 'Event Finder',
					category: 'Invoicing',
					subcategory: '',
				},
			]
        }
    }
    render() {
        return (
				/* <!-- Portfolio --> */
				<section className="flat-row pd-portfolio-s3" id="work">  
					<TabPortfolio />
					<div className="container">   
						<div className="row">
							<div className="col-md-12">
								<div className="dividers portfolio"></div>
								<div className="flat-portfolio">             
									<div className="portfolio-wrap portfolio-gallery one-four clearfix">
										{
											this.state.itemcontent.map(data =>(
												<div key={data.id} className={data.classitem}>
													<div className="wrap-iconbox">
														<div className="featured-post">
															<img src={data.srcimg} alt="img" />
														</div>
														<div className="title-post">
															<Link to="#">{data.title}</Link>
														</div>
														<div className="category-post">
															<Link to="#" title="">{data.category} </Link>
															<Link to="#" title="">{data.subcategory}</Link>
														</div>
													</div> 
												</div>
											))
										}
										</div>
								</div>
							</div>
						</div>
		        </div>
				</section> 

				 
        );
    }
}

export default Layout;