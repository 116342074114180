import React, { Component } from 'react'
import {Link} from 'react-router-dom'
class EventBoxs2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataEvents: [
                {
                    id: 4,
                    // classicon: 'icon-rounded magic',
                    title: 'Construction Equipment & Machinery',
                    description: 'We provide a wide range of construction equipment and machinery for all your construction needs',
                    url: '/Construction'
                },
                {
                    id: 5,
                    // classicon: 'icon-rounded artboard',
                    title: 'Transportation & Logistics',
                    description: 'Our fleet of trucks and trailers are available for hire for all your transportation and logistics needs',
                    url: '/Transportation'
                },
                {
                    id: 6,
                    // classicon: 'icon-rounded global',
                    title: 'General Trading',
                    description: 'We have a wide range of products and services available for all your general trading needs',
                    url: '/GeneralTrading'
                },
            ]
        }
    }
    
    render() {
        return (
            <div className="row">
                {
                    this.state.dataEvents.map(data => (
                        <div className="col-md-4" key={data.id}>
                            <div className="iconbox-item">
                                <div className="iconbox style1">
                                    <div className="box-header">
                                        <div className={data.classicon}>
                                            
                                        </div>
                                        {/* <!-- /.icon-rounded --> */}
                                        <div className="box-title">
                                            <Link to={data.url} title="">{data.title}</Link>
                                        </div>
                                        {/* <!-- /.box-title --> */}
                                        </div>
                                        {/* <!-- /.box-header --> */}
                                    <div className="box-content">{data.description}</div>
                                    {/* <!-- /.box-content --> */}
                                </div>
                                 {/* <!-- /.iconbox --> */}
						    </div>
                            {/* <!-- /.iconbox-item --> */}
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default EventBoxs2;