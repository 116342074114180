import React, { Component } from 'react';
import {Link} from 'react-router-dom'
class TopBar extends Component {
    render() {
        return (
            <div  className="top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <ul className="flat-infomation">
                                <li className="phone">Call us: <Link to="+255657005000" title="phone">+255 657 005 000</Link></li>
                                <li className="email">Email: <Link to="mailto:info@sumry.co.tz" title="email">info@sumry.co.tz</Link></li>
                            </ul>
                            <div className="flat-questions">
                                <Link to="#" title="" className="questions">Have a project in mind?</Link>
                                <Link to="#" title="" className="appointment">LET'S MAKE IT A REALITY</Link>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
		    </div>
        );
    }
}

export default TopBar;