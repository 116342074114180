import React, {Component} from 'react';
import {Link} from 'react-router-dom'

class BottomBar extends Component {
    render() {
        return (
            <div className="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="copyright">
                                <p>Copyright © {new Date().getFullYear()} SUMRY's ENTERPRISES LTD</p>
                            </div>
                            <ul className="menu-footer">
                                <li><Link to="twitter.com/sumrytz"><span className="fa fa-twitter"/></Link></li>
                                <li><Link to="linkedin.com/company/sumrytz"><span className="fa fa-linkedin-square"/></Link>
                                </li>
                                <li><Link to="facebook.com/sumrytz"><span className="fa fa-facebook-official"/></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            /* <!-- /.footer-bottom --> */
        );
    }
}

export default BottomBar;