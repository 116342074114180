import React, {Component} from 'react';

class BoxView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgslide: [
                {
                    id: '1',
                    srcimg: 'images/about/s01.jpg'
                },
                {
                    id: '2',
                    srcimg: 'images/about/s01.jpg'
                }
            ],
            content: [
                {
                    id: '1',
                    title: 'Our Vision',
                    description:
                        'We aim to be a leading Tanzania Company in road and civil engineering contracting and in the provision of Construction Materials,' +
                        'Mining Services, Office Furniture, Home Appliances, and Electronics, meeting and satisfying the demands and expectations of stakeholders involved in the development of ' +
                        'Tanzania in all sectors of the economy.'
                },
                {
                    id: '2',
                    title: 'Mission Statement',
                    description:
                        <>
                            <li>To maintain friendly employment policies to ensure dynamic professionalism in our
                                operations
                                and avoid turnover of employees.
                            </li>
                            <li>To maintain time management in order to ensure effective and efficient services to our
                                clients.
                            </li>
                            <li>To be mobile and well equipped.</li>
                            <li>To professionally manage relationships with our clients and stakeholders with respect
                                and integrity.
                            </li>
                            <li>To open more outlets in strategic locations nationwide where our home appliances, office
                                furniture, home furniture, and electronic items shall be displayed and sold .
                            </li>
                            <li>To maintain adherence to national policies in ensuring best and safe practices when it
                                comes to social, health and safety responsibilities.
                            </li>
                            <li>To observe and apply best practices when it comes to governance and management .</li>
                        </>

                }
            ],
            blogtimeline: [
                // {
                //     id: '1',
                //     year: '2011',
                //     title: 'Started Gama Incorporation Ltd',
                //     description: 'Gama Incorporation Ltd deals with solving technological problems. We are a team of professionals with a passion for excellence and a commitment to deliver quality services to our clients.',
                // },
                // {
                //     id: '2',
                //     year: '2011',
                //     title: 'Started Gama Incorporation Ltd',
                //     description: 'Gama Incorporation Ltd deals with solving technological problems. We are a team of professionals with a passion for excellence and a commitment to deliver quality services to our clients.',
                // },
            ]

        }
    }

    render() {
        return (
            <div>
                <div className="flexslider s2">
                    <div className="flat-slides">
                        <ul className="slides">
                            {
                                this.state.imgslide.map(data => (
                                    <li key={data.id}>
                                        <img src={data.srcimg} alt="img"/>
                                    </li>
                                ))
                            }

                        </ul>
                    </div>
                </div>
                {
                    this.state.content.map(data => (
                        <div className="box-content" key={data.id}>
                            <div className="title">{data.title}</div>
                            <p>{data.description}</p>
                            <div className="dividers dividers-bc-v4"></div>
                        </div>
                    ))
                }

                {
                    this.state.blogtimeline.map(data => (
                        <div className="flat-text-block-timeline" key={data.id}>
                            <div className="year">{data.year}</div>
                            <div className="flat-timeline-content">
                                <div className="box-content">
                                    <div className="title">{data.title}</div>
                                    <p>{data.description}</p>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>

        )

    }
}

export default BoxView;